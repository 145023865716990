import axios from "axios";

export interface CommentedQuestion {
    id: number;
    body: string;
    latest_comment_created_at: number;
}

export class CommentedQuestionRepository {
  private static readonly DOMAIN = process.env.VUE_APP_DOMAIN;
  private url: string;
  constructor(private projectId: number) {
    this.url = CommentedQuestionRepository.DOMAIN + "v1/projects/" + this.projectId + "/commented_question/";
  }
  async getReport(): Promise<CommentedQuestion[]> {
    return axios.get(this.url).then((response) => {
      return response.data.questions;
    });
  }
}
